//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import minxin from '@/views/fileStream/archived/minxins.js'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import fileDownAndPreView from '@/views/fileStream/documentSend/components/fileDownAndPreView.vue'
import showEditor from '@/views/fileStream/documentSend/components/showEditor.vue'
export default {
  props: {
    content: {
      type: String,
      default: '輸入正文',
    },
    formObj: {
      type: Object,
      default() {
        return { name: '發文稿紙' }
      },
    },
  },
  mixins: [minxin],
  components: {
    OpenData,
    fileDownAndPreView,
    nodes,
    showEditor
  },
  data() {
    return {
      ngdw_list: [],
      ngry_list: [],
    }
  },
  computed: {
    list() {
      if (this.formObj.documentNodeList && this.formObj.documentNodeList.length) {
        const nodes = this.formObj.documentNodeList.slice()
        return nodes.filter((j) => j.sort !== -1 && ![99, 100].includes(j.sort))
      }
      return []
    },
  },
  methods: {
    formatListMess(node) {
      const arr = []
      if (node.deptInfos && node.deptInfos.length > 0) {
        node.deptInfos.forEach((dep) => {
          dep.deptUsers.forEach((k) => {
            arr.push(k)
          })
        })
      }
      node.nodes = [...node.nodes, ...arr].filter((j) => j.userId && j.approvalFlag !== 2)
    },
    formatList(list) {
      const listCopy = JSON.parse(JSON.stringify(list))
      listCopy.forEach((j) => {
        this.formatListMess(j)
      })
      return listCopy.filter((j) => j.sort != -1 && ![99, 100].includes(j.sort))
    },
  },
}
